<template>
  <v-dialog
    content-class="ui-layer"
    v-model="show"
    persistent
    eager
    internal-activator
    :width="modalSize"
    ref="dialogRef"
  >
    <v-card>
      <section class="title">
        <button class="go-back" @click="onClose()">
          <v-icon color="#304251" size="22">mdi-chevron-left</v-icon>
          <span>GO BACK</span>
        </button>
      </section>
      <v-card-text v-if="show" ref="videoPreview">
        <video
          title="video preview"
          class="video-preview"
          :src="`${videoUrl}`"
          :width="videoWidthtSize"
          :height="videoHeightSize"
          controls
          muted
          autoplay
        ></video>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show", "videoUrl"],
  data() {
    return {
      videoHeightSize: 630,
      videoWidthtSize: 1150,
      modalSize: 1150,
    };
  },
  methods: {
    onClose() {
      this.$emit("hide");
    },
    handleHeightSizeVideo() {
      if (!this.show) return;
      const maxHeightShowVideo =
        this.$vuetify.breakpoint.height - (24 * 2 + 51 + 40);
      const maxWidthShowVideo =
        this.$vuetify.breakpoint.width - (24 * 2 + 24 * 2 + 20);
      if ((maxWidthShowVideo * 9) / 16 > maxHeightShowVideo) {
        this.videoHeightSize = maxHeightShowVideo;
      } else {
        this.videoHeightSize = Math.ceil((maxWidthShowVideo * 9) / 16);
      }
      const widthVideo = Math.ceil((this.videoHeightSize * 16) / 9);
      this.videoWidthtSize = widthVideo;
      this.modalSize = widthVideo + 50;
    },
  },
  mounted: function() {
    this.handleHeightSizeVideo;
    window.addEventListener("resize", this.handleHeightSizeVideo);
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleHeightSizeVideo);
  },
  watch: {
    show: function(value) {
      if (value) {
        this.handleHeightSizeVideo();
      }
    },
  },
};
</script>

<style lang="scss">
.ui-layer {
  .v-dialog > .v-card > .v-card__text {
    padding: 0px 22px 25px 22px;
  }
  .v-sheet.v-card {
    border-radius: 15px;
  }
  .title {
    padding-top: 24px;
    padding-left: 30px;
    margin-bottom: 20px;
    .go-back {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 16px;
      color: #304251;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      border: 1px solid white;
      line-height: 25px;
      .v-icon {
        align-self: center;
      }
    }
  }
  .video-preview {
    width: 100%;
  }
}
.player {
  padding: 24px 22px 25px 22px;
  overflow: hidden;
  position: relative;
  width: 100%;
  &::after {
    padding-top: 50.25%;
    display: block;
    content: "";
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
