import { render, staticRenderFns } from "./Radio.vue?vue&type=template&id=ca61fede&"
import script from "./Radio.vue?vue&type=script&lang=js&"
export * from "./Radio.vue?vue&type=script&lang=js&"
import style0 from "./Radio.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadio,VRadioGroup})
