import { api } from "@goldfishcode/modern-groom-api-sdk";
export const uploadImage = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return api.UploadFile.uploadImage(formData);
};

export const uploadMultiImage = (files) => {
  const formData = new FormData();
  for (let index = 0; index < files.length; index++) {
    formData.append("file", files[index]);
  }
  return api.UploadFile.uploadMultiImages(formData);
};
