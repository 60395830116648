var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{ref:"dialogRef",attrs:{"content-class":"ui-modal","persistent":"","eager":"","internal-activator":"","scrollable":true},on:{"click:outside":function($event){return _vm.handleClickClose()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.handleClickClose()}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('section',{staticClass:"header"},[_c('span',[_vm._v("Upload Photos")]),_c('button',{staticClass:"close-btn",on:{"click":function($event){return _vm.handleClickClose()}}},[_c('v-icon',[_vm._v(" mdi-close")])],1)]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"upload-photo"},[_c('h4',{staticClass:"upload-title"},[_vm._v(" Upload a full length forward facing photo of yourself "),_c('span',[_vm._v("*")])]),_c('img',{staticClass:"image",attrs:{"src":_vm.previewFile.facingImage || _vm.facingImageUrl || _vm.defaultImage}}),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.onChooseFile('facingImgId')}}},[_vm._v(" "+_vm._s(_vm.facingImage && _vm.facingImage.name ? _vm.facingImage.name : _vm.facingImageUrl ? _vm.facingImageUrl : "Choose File")+" ")]),_c('v-file-input',{ref:"facingImgRef",attrs:{"id":"facingImgId","type":"file","name":"facing-img","rules":_vm.facingImageUrl ? [] : _vm.rules.required,"accept":"image/*"},on:{"change":function($event){return _vm.onSelectFile($event, 'facingImage')}},model:{value:(_vm.facingImage),callback:function ($$v) {_vm.facingImage=$$v},expression:"facingImage"}}),_c('h5',{staticClass:"upload-sub-title"},[_vm._v(" Do us a favor and please be clothed ")]),(
              _vm.$refs.facingImgRef &&
              _vm.$refs.facingImgRef.validations &&
              _vm.$refs.facingImgRef.validations[0]
            )?_c('v-slide-y-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$refs.facingImgRef.validations[0]),expression:"$refs.facingImgRef.validations[0]"}],staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" This field is required. ")])])])])]):_vm._e()],1),_c('v-divider'),_c('div',{staticClass:"upload-photo"},[_c('h4',{staticClass:"upload-title"},[_vm._v(" Upload a full length side/profile photo of yourself "),_c('span',[_vm._v("*")])]),_c('img',{staticClass:"image",attrs:{"src":_vm.previewFile.userSuitImage || _vm.userSuitImageUrl || _vm.defaultImage}}),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.onChooseFile('userSuitImgId')}}},[_vm._v(" "+_vm._s(_vm.userSuitImage && _vm.userSuitImage.name ? _vm.userSuitImage.name : _vm.userSuitImageUrl ? _vm.userSuitImageUrl : "Choose File")+" ")]),_c('h5',{staticClass:"upload-sub-title"},[_vm._v(" Do us a favor and please be clothed ")]),_c('v-file-input',{ref:"userSuitImgRef",attrs:{"id":"userSuitImgId","type":"file","rules":_vm.userSuitImageUrl ? [] : _vm.rules.required,"name":"user-suit-img","accept":"image/*"},on:{"change":function($event){return _vm.onSelectFile($event, 'userSuitImage')}},model:{value:(_vm.userSuitImage),callback:function ($$v) {_vm.userSuitImage=$$v},expression:"userSuitImage"}}),(
              _vm.$refs.userSuitImgRef &&
              _vm.$refs.userSuitImgRef.validations &&
              _vm.$refs.userSuitImgRef.validations[0]
            )?_c('v-slide-y-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$refs.userSuitImgRef.validations[0]),expression:"$refs.userSuitImgRef.validations[0]"}],staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" This field is required. ")])])])])]):_vm._e()],1),_c('v-divider'),_c('div',{staticClass:"describe-build"},[_c('h4',{staticClass:"describe-build-title"},[_vm._v(" Describe your Build Type: "),_c('span',[_vm._v("*")])]),_c('v-textarea',{ref:"descriptBuildRef",attrs:{"rules":_vm.rules.required,"solo":"","name":"input-7-4","label":"Type here...","hide-details":"true"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('h5',{staticClass:"describe-build-sub-title"},[_vm._v(" Ex: long arm span, broad shoulders, tall/lean, average build, muscular/big thighs, long/short torso, long/short legs, carries weight in midsection, athletic build, etc. ")]),(
              _vm.$refs.descriptBuildRef &&
              _vm.$refs.descriptBuildRef.validations &&
              _vm.$refs.descriptBuildRef.validations[0]
            )?_c('v-slide-y-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$refs.descriptBuildRef.validations[0]),expression:"$refs.descriptBuildRef.validations[0]"}],staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" This field is required. ")])])])])]):_vm._e()],1),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.handClickDone($event)}}},[_vm._v("Done")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }