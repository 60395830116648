var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.suitId)?_c('div',{staticClass:"container-form"},[_c('section',[_c('button',{staticClass:"go-back",on:{"click":function($event){return _vm.goBack()}}},[_c('v-icon',{attrs:{"color":"#304251","size":"22"}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v("GO BACK")])],1),_c('br'),_vm._m(0),_c('h4',{staticClass:"suit-title"},[_vm._v("Let’s Get Fitted")]),_c('p',{staticClass:"help-text"},[_vm._v(" Please complete the Sizing Questionnaire below. For further assistance, you can click the questionmark icon next to each measurement for helpful \"how to measure\" videos! ")])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"custom-gutters",attrs:{"align":"end"}},[_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"value":_vm.formData.age,"isPlaceholder":true,"items":[
            { id: '', name: 'Select Age' } ].concat( (_vm.physicalSizes.AGE ? _vm.physicalSizes.AGE : {}) ),"rules":_vm.rules.required,"label":"Your age","placeholder":"Select Age"},on:{"onChange":function (v) { return (_vm.formData.age = v); }}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"value":_vm.formData.height,"rules":_vm.rules.required,"isPlaceholder":true,"items":[
              { id: '', name: 'Select Feet' } ].concat( (_vm.physicalSizes.HEIGHT ? _vm.physicalSizes.HEIGHT : {}) ),"placeholder":"Select Feet","label":"Your Height"},on:{"onChange":function (v) { return (_vm.formData.height = v); }}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"isPlaceholder":true,"value":_vm.formData.inch,"items":[
            { id: '', name: 'Select Inches' } ].concat( (_vm.physicalSizes.INCHES ? _vm.physicalSizes.INCHES : {}) ),"placeholder":"Select Inches","label":""},on:{"onChange":function (v) { return (_vm.formData.inch = v); }}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Input',{attrs:{"label":"Your Weight","placeholder":"Enter Weight","rules":_vm.rules.required,"value":_vm.formData.weight},on:{"onChange":function (v) { return (_vm.formData.weight = v); }}})],1)],1),_c('v-divider'),_c('v-row',{staticClass:"custom-gutters nth-item"},[_c('v-col',{staticClass:"custom-col-gutters"},[_c('Radio',{attrs:{"data":_vm.chestStructure,"radioSelect":_vm.formData.chest_structure,"label":"Chest Structure"},on:{"onSelect":function (v) { return (_vm.formData.chest_structure = v); }}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Radio',{attrs:{"data":_vm.stomachStructure,"radioSelect":_vm.formData.stomach_structure,"label":"Stomach Structure"},on:{"onSelect":function (v) { return (_vm.formData.stomach_structure = v); }}})],1)],1),_c('v-divider'),_c('v-row',{staticClass:"custom-gutters nth-item",attrs:{"align":"end"}},[_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"isPlaceholder":true,"value":_vm.formData.t_shirt_size,"items":[
              { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.T_SHIRT_SIZE
                ? _vm.physicalSizes.T_SHIRT_SIZE
                : {}) ),"rules":_vm.suitData && _vm.suitData.shirt_color !== 'Opt-out of shirt'
                ? _vm.rules.required
                : undefined,"label":"Polo / T-Shirt Size:","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.t_shirt_size = v); }}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"value":_vm.formData.jean_waist_size,"isPlaceholder":true,"items":[
              { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.JEAN_WAIST_SIZE
                ? _vm.physicalSizes.JEAN_WAIST_SIZE
                : {}) ),"rules":_vm.rules.required,"label":"Jean Waist Size","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.jean_waist_size = v); }}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"isPlaceholder":true,"value":_vm.formData.suit_size,"items":[
              { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.SUIT_SIZE ? _vm.physicalSizes.SUIT_SIZE : {}) ),"smallLabel":"If you currently own a suit, what size is it?","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.suit_size = v); }}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"isPlaceholder":true,"value":_vm.formData.dress_shirt_size,"items":[
              { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.DRESS_SHIRT_SIZE
                ? _vm.physicalSizes.DRESS_SHIRT_SIZE
                : {}) ),"smallLabel":"If you currently own a dress shirt, what size is it?","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.dress_shirt_size = v); }}})],1)],1),_c('v-divider'),_c('v-row',{staticClass:"custom-gutters nth-item"},[_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"isPlaceholder":true,"questionMark":true,"value":_vm.formData.neck_size,"items":[
              { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.NECK_SIZE ? _vm.physicalSizes.NECK_SIZE : {}) ),"rules":_vm.rules.required,"label":"Neck Size","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.neck_size = v); },"questionMarkClick":function($event){return _vm.showIntroVideo(_vm.videoUrl.MEASURING_THE_NECK)}}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"questionMark":true,"isPlaceholder":true,"value":_vm.formData.sleeve_size,"items":[
              { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.SLEEVE_SIZE ? _vm.physicalSizes.SLEEVE_SIZE : {}) ),"rules":_vm.rules.required,"label":"Sleeve Length:","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.sleeve_size = v); },"questionMarkClick":function($event){return _vm.showIntroVideo(_vm.videoUrl.MEASURING_THE_SLEEVE)}}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"questionMark":true,"value":_vm.formData.chest_size,"isPlaceholder":true,"items":[
              { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.CHEST_SIZE ? _vm.physicalSizes.CHEST_SIZE : {}) ),"rules":_vm.rules.required,"label":"Chest","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.chest_size = v); },"questionMarkClick":function($event){return _vm.showIntroVideo(_vm.videoUrl.MEASURING_THE_CHEST)}}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"value":_vm.formData.jacket_waist,"isPlaceholder":true,"questionMark":true,"items":[
              { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.JACKET_WAIST ? _vm.physicalSizes.JACKET_WAIST : {}) ),"rules":_vm.rules.required,"label":"Jacket Waist","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.jacket_waist = v); },"questionMarkClick":function($event){return _vm.showIntroVideo(_vm.videoUrl.MEASURING_THE_JACKET_WAIST)}}})],1)],1),_c('v-divider'),_c('v-row',{staticClass:"custom-gutters nth-item row-5"},[_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"questionMark":true,"isPlaceholder":true,"value":_vm.formData.waist,"items":[
              { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.WAIST ? _vm.physicalSizes.WAIST : {}) ),"rules":_vm.rules.required,"label":"Waist","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.waist = v); },"questionMarkClick":function($event){return _vm.showIntroVideo(_vm.videoUrl.MEASURING_THE_WAIST)}}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('Select',{attrs:{"isPlaceholder":true,"value":_vm.formData.seat,"questionMark":true,"items":[
              { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.SEAT ? _vm.physicalSizes.SEAT : {}) ),"rules":_vm.rules.required,"label":"Seat","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.seat = v); },"questionMarkClick":function($event){return _vm.showIntroVideo(_vm.videoUrl.MEASURING_THE_SEAT)}}})],1),_c('v-col',{staticClass:"custom-col-gutters"},[_c('section',{staticClass:"shoe-size"},[_c('Select',{attrs:{"isPlaceholder":true,"value":_vm.formData.shoes_size,"items":[
                { id: '', name: 'Select Size' } ].concat( (_vm.physicalSizes.SHOE_SIZE ? _vm.physicalSizes.SHOE_SIZE : {}) ),"rules":_vm.suitData &&
                _vm.suitData.more_item_obj &&
                _vm.suitData.more_item_obj.price !== 0
                  ? _vm.rules.required
                  : undefined,"label":"Shoe Size","placeholder":"Select Size"},on:{"onChange":function (v) { return (_vm.formData.shoes_size = v); }}})],1)])],1),_c('v-divider'),_c('section',{staticClass:"action nth-item"},[_c('section',{staticClass:"upload-file"},[_c('h4',{staticClass:"upload-title"},[_vm._v(" Please click below to upload a couple full length photos for us ")]),_c('h5',{staticClass:"upload-sub-title"},[_vm._v(" Do us a favor and please be clothed! ")]),_c('button',{attrs:{"type":"button"},on:{"click":function () {
              _vm.isOpenModalUpload = true;
            }}},[_vm._v(" Upload Photos ")]),(
            _vm.$refs.fileRef &&
              !_vm.$refs.fileRef.value &&
              _vm.$refs.fileRef.validations &&
              _vm.$refs.fileRef.validations[0]
          )?_c('v-slide-y-transition',[_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" This field is required. ")])])])])]):_vm._e(),_c('v-file-input',{ref:"fileRef",staticClass:"hidden-field",attrs:{"type":"file","rules":_vm.formData.avatar ? [] : _vm.rules.required},model:{value:(_vm.formData.files.avatar),callback:function ($$v) {_vm.$set(_vm.formData.files, "avatar", $$v)},expression:"formData.files.avatar"}})],1),_c('section',{staticClass:"continue"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.onContinue($event)}}},[_vm._v("Continue")])])])],1),_c('UploadPhotosModal',{attrs:{"show":_vm.isOpenModalUpload,"facingImageUrl":_vm.formData.avatar,"userSuitImageUrl":_vm.formData.user_image,"desciptioInput":_vm.formData.description},on:{"onOk":function (data) {
          var facingImage = data.facingImage;
          var userSuitImage = data.userSuitImage;
          var description = data.description;
          _vm.formData = Object.assign({}, _vm.formData,
            {description: description,
            files: {
              avatar: facingImage,
              user_image: userSuitImage,
            }});
          _vm.isOpenModalUpload = false;
        },"onClose":function () { return (_vm.isOpenModalUpload = false); }}}),_c('Layer',{attrs:{"show":_vm.layer.show,"videoUrl":_vm.layer.videoUrl},on:{"hide":function () { return (_vm.layer.show = false); }}})],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"size":50,"color":"black","indeterminate":""}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"img-file"},[_c('img',{attrs:{"src":require("../assets/img/icon/icon-shirt.png"),"alt":"shirt"}})])}]

export { render, staticRenderFns }