<template>
  <section>
    <span class="ui-radio-span" v-if="label">{{ label }}</span>
    <v-radio-group v-model="radios" row class="ui-radio-group">
      <v-radio
        v-for="(data, index) in data"
        :label="data.label"
        :value="data.value"
        :key="index"
        class="ui-radio"
        active-class="ui-radio-active"
        :hide-details="hideDetail ? 'auto' : false"
      >
      </v-radio>
    </v-radio-group>
  </section>
</template>

<script>
export default {
  props: ["data", "radioSelect", "label", "hideDetail"],
  data: () => ({
    radios: null,
  }),
  watch: {
    radioSelect: function(val) {
      this.radios = val;
    },
    radios: function() {
      this.$emit("onSelect", this.radios);
    },
  },
  created: function() {
    this.radios = this.radioSelect || null;
  },
};
</script>

<style lang="scss">
.ui-radio-span {
  display: block;
  margin-bottom: 10px;
  font-family: "Poppins";
  font-weight: 500;
  color: #304251;
  font-size: 14px;
}
.ui-radio-group {
  margin-top: 0px;
  padding-top: 0px;
  .v-input__slot {
    margin-bottom: 4px;
  }
  .v-input__control {
    .v-input__slot {
      .v-input--radio-group__input {
        @media only screen and (max-width: 767px) {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 10px;
        }
        @media only screen and (max-width: 576px) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 0;
        }
      }
    }
  }
}
.ui-radio {
  .v-label {
    font-family: "Source Sans Pro";
    font-size: 14px;
    color: #304251 !important;
  }
  .v-input--selection-controls__input {
    width: 28px;
    max-width: 28px;
    height: 28px;
    max-height: 28px;
    .v-input--selection-controls__ripple {
      display: none;
    }
  }
  .v-icon {
    border: 1px solid #8e9c9c;
    border-radius: 50%;
    &::before {
      content: "";
    }
  }
}
.ui-radio-active {
  .v-icon::before {
    width: 20px;
    height: 20px;
    background: #ca6d4f;
    border-radius: 50%;
  }
}
</style>
