<template>
  <div>
    <div class="container-form" v-if="suitId">
      <section>
        <button class="go-back" @click="goBack()">
          <v-icon color="#304251" size="22">mdi-chevron-left</v-icon>
          <span>GO BACK</span>
        </button>
        <br />
        <section class="img-file">
          <img src="../assets/img/icon/icon-shirt.png" alt="shirt" />
        </section>
        <h4 class="suit-title">Let’s Get Fitted</h4>
        <p class="help-text">
          Please complete the Sizing Questionnaire below. For further assistance, you can click the questionmark icon
          next to each measurement
          for helpful "how to measure" videos!
        </p>
      </section>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-row align="end" class="custom-gutters">
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.age = v)" :value="formData.age" :isPlaceholder="true" :items="[
              { id: '', name: 'Select Age' },
              ...(physicalSizes.AGE ? physicalSizes.AGE : {}),
            ]" :rules="rules.required" label="Your age" placeholder="Select Age" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.height = v)" :value="formData.height" :rules="rules.required"
              :isPlaceholder="true" :items="[
                { id: '', name: 'Select Feet' },
                ...(physicalSizes.HEIGHT ? physicalSizes.HEIGHT : {}),
              ]" placeholder="Select Feet" label="Your Height" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.inch = v)" :isPlaceholder="true" :value="formData.inch" :items="[
              { id: '', name: 'Select Inches' },
              ...(physicalSizes.INCHES ? physicalSizes.INCHES : {}),
            ]" placeholder="Select Inches" label="" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Input label="Your Weight" placeholder="Enter Weight" :rules="rules.required"
              @onChange="(v) => (formData.weight = v)" :value="formData.weight" />
          </v-col>
        </v-row>
        <v-divider />

        <v-row class="custom-gutters nth-item">
          <v-col class="custom-col-gutters">
            <Radio :data="chestStructure" :radioSelect="formData.chest_structure"
              @onSelect="(v) => (formData.chest_structure = v)" label="Chest Structure" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Radio :data="stomachStructure" :radioSelect="formData.stomach_structure"
              @onSelect="(v) => (formData.stomach_structure = v)" label="Stomach Structure" />
          </v-col>
        </v-row>
        <v-divider />

        <v-row align="end" class="custom-gutters nth-item">
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.t_shirt_size = v)" :isPlaceholder="true" :value="formData.t_shirt_size"
              :items="[
                { id: '', name: 'Select Size' },
                ...(physicalSizes.T_SHIRT_SIZE
                  ? physicalSizes.T_SHIRT_SIZE
                  : {}),
              ]" :rules="
                suitData && suitData.shirt_color !== 'Opt-out of shirt'
                  ? rules.required
                  : undefined
              " label="Polo / T-Shirt Size:" placeholder="Select Size" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.jean_waist_size = v)" :value="formData.jean_waist_size"
              :isPlaceholder="true" :items="[
                { id: '', name: 'Select Size' },
                ...(physicalSizes.JEAN_WAIST_SIZE
                  ? physicalSizes.JEAN_WAIST_SIZE
                  : {}),
              ]" :rules="rules.required" label="Jean Waist Size" placeholder="Select Size" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.suit_size = v)" :isPlaceholder="true" :value="formData.suit_size"
              :items="[
                { id: '', name: 'Select Size' },
                ...(physicalSizes.SUIT_SIZE ? physicalSizes.SUIT_SIZE : {}),
              ]" smallLabel="If you currently own a suit, what size is it?" placeholder="Select Size" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.dress_shirt_size = v)" :isPlaceholder="true"
              :value="formData.dress_shirt_size" :items="[
                { id: '', name: 'Select Size' },
                ...(physicalSizes.DRESS_SHIRT_SIZE
                  ? physicalSizes.DRESS_SHIRT_SIZE
                  : {}),
              ]" smallLabel="If you currently own a dress shirt, what size is it?" placeholder="Select Size" />
          </v-col>
        </v-row>
        <v-divider />

        <v-row class="custom-gutters nth-item">
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.neck_size = v)" :isPlaceholder="true"
              @questionMarkClick="showIntroVideo(videoUrl.MEASURING_THE_NECK)" :questionMark="true"
              :value="formData.neck_size" :items="[
                { id: '', name: 'Select Size' },
                ...(physicalSizes.NECK_SIZE ? physicalSizes.NECK_SIZE : {}),
              ]" :rules="rules.required" label="Neck Size" placeholder="Select Size" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.sleeve_size = v)"
              @questionMarkClick="showIntroVideo(videoUrl.MEASURING_THE_SLEEVE)" :questionMark="true"
              :isPlaceholder="true" :value="formData.sleeve_size" :items="[
                { id: '', name: 'Select Size' },
                ...(physicalSizes.SLEEVE_SIZE ? physicalSizes.SLEEVE_SIZE : {}),
              ]" :rules="rules.required" label="Sleeve Length:" placeholder="Select Size" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.chest_size = v)"
              @questionMarkClick="showIntroVideo(videoUrl.MEASURING_THE_CHEST)" :questionMark="true"
              :value="formData.chest_size" :isPlaceholder="true" :items="[
                { id: '', name: 'Select Size' },
                ...(physicalSizes.CHEST_SIZE ? physicalSizes.CHEST_SIZE : {}),
              ]" :rules="rules.required" label="Chest" placeholder="Select Size" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.jacket_waist = v)" :value="formData.jacket_waist" :isPlaceholder="true"
              :questionMark="true" @questionMarkClick="showIntroVideo(videoUrl.MEASURING_THE_JACKET_WAIST)" :items="[
                { id: '', name: 'Select Size' },
                ...(physicalSizes.JACKET_WAIST ? physicalSizes.JACKET_WAIST : {}),
              ]" :rules="rules.required" label="Jacket Waist" placeholder="Select Size" />
          </v-col>

        </v-row>
        <v-divider />
        <v-row class="custom-gutters nth-item row-5">
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.waist = v)"
              @questionMarkClick="showIntroVideo(videoUrl.MEASURING_THE_WAIST)" :questionMark="true"
              :isPlaceholder="true" :value="formData.waist" :items="[
                { id: '', name: 'Select Size' },
                ...(physicalSizes.WAIST ? physicalSizes.WAIST : {}),
              ]" :rules="rules.required" label="Waist" placeholder="Select Size" />
          </v-col>
          <v-col class="custom-col-gutters">
            <Select @onChange="(v) => (formData.seat = v)" :isPlaceholder="true" :value="formData.seat"
              :questionMark="true" @questionMarkClick="showIntroVideo(videoUrl.MEASURING_THE_SEAT)" :items="[
                { id: '', name: 'Select Size' },
                ...(physicalSizes.SEAT ? physicalSizes.SEAT : {}),
              ]" :rules="rules.required" label="Seat" placeholder="Select Size" />
          </v-col>
          <v-col class="custom-col-gutters">
            <section class="shoe-size">
              <Select @onChange="(v) => (formData.shoes_size = v)" :isPlaceholder="true" :value="formData.shoes_size"
                :items="[
                  { id: '', name: 'Select Size' },
                  ...(physicalSizes.SHOE_SIZE ? physicalSizes.SHOE_SIZE : {}),
                ]" :rules="
                  suitData &&
                  suitData.more_item_obj &&
                  suitData.more_item_obj.price !== 0
                    ? rules.required
                    : undefined
                " label="Shoe Size" placeholder="Select Size" />
            </section>
          </v-col>
        </v-row>
        <v-divider />
        <section class="action nth-item">
          <section class="upload-file">
            <h4 class="upload-title">
              Please click below to upload a couple full length photos for us
            </h4>
            <h5 class="upload-sub-title">
              Do us a favor and please be clothed!
            </h5>
            <button type="button" @click="
              () => {
                isOpenModalUpload = true;
              }
            ">
              Upload Photos
            </button>
            <v-slide-y-transition v-if="
              $refs.fileRef &&
                !$refs.fileRef.value &&
                $refs.fileRef.validations &&
                $refs.fileRef.validations[0]
            ">
              <div class="v-text-field__details">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      This field is required.
                    </div>
                  </div>
                </div>
              </div>
            </v-slide-y-transition>
            <!-- hidden fields -->

            <v-file-input type="file" v-model="formData.files.avatar" :rules="formData.avatar ? [] : rules.required"
              class="hidden-field" ref="fileRef" />
          </section>
          <section class="continue">
            <button type="button" @click="onContinue($event)">Continue</button>
          </section>
        </section>
      </v-form>
      <UploadPhotosModal :show="isOpenModalUpload" :facingImageUrl="formData.avatar"
        :userSuitImageUrl="formData.user_image" :desciptioInput="formData.description" @onOk="
          (data) => {
            const { facingImage, userSuitImage, description } = data;
            formData = {
              ...formData,
              description,
              files: {
                avatar: facingImage,
                user_image: userSuitImage,
              },
            };
            isOpenModalUpload = false;
          }
        " @onClose="() => (isOpenModalUpload = false)" />
      <Layer :show="layer.show" :videoUrl="layer.videoUrl" @hide="() => (layer.show = false)" />
    </div>
    <div class="loading" v-if="loading">
      <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
// component
import Radio from "./ui/Radio";
import Input from "./ui/Input";
import Select from "./ui/Select";
import Layer from "./modal/Layer";
// api
import { api } from "@goldfishcode/modern-groom-api-sdk";
import { uploadImage, uploadMultiImage } from "../controllers/file.js";
import UploadPhotosModal from "./modal/UploadPhotos.vue";

export default {
  data: () => ({
    valid: false,
    suitId: null,
    userId: null,
    loading: false,
    formData: {
      age: "",
      height: "",
      weight: "",
      inch: "",
      chest_structure: "MUSCULAR",
      stomach_structure: "FLAT",
      jean_waist_size: "",
      waist: "",
      suit_size: "",
      dress_shirt_size: "",
      sleeve_size: "",
      t_shirt_size: "",
      jacket_waist: "",
      seat: "",
      shoes_size: "",
      avatar: "",
      user_image: "",
      description: "",
      files: {
        avatar: undefined,
        user_image: undefined,
      },
    },
    suitData: {},
    videoUrl: {
      MEASURING_THE_JACKET_WAIST: 'https://modern-groom-videos.s3.amazonaws.com/Measuring+the+Jacket+Waist+VO.mp4',
      MEASURING_THE_NECK: 'https://modern-groom-videos.s3.amazonaws.com/Measuring+the+Neck+VO.mp4',
      MEASURING_THE_SLEEVE: 'https://modern-groom-videos.s3.amazonaws.com/Measuring+the+Sleeve+VO.mp4',
      MEASURING_THE_SEAT: 'https://modern-groom-videos.s3.amazonaws.com/Measuring+the+Seat+VO.mp4',
      MEASURING_THE_CHEST: 'https://modern-groom-videos.s3.amazonaws.com/Measuring+the+Chest+VO.mp4',
      MEASURING_THE_WAIST: 'https://modern-groom-videos.s3.amazonaws.com/Measuring+the+Waist+VO.mp4',
    },
    files: [],
    rules: {
      required: [(v) => !!v || "This field is required."],
    },
    chestStructure: [
      {
        label: "Muscular",
        value: "MUSCULAR",
      },
      {
        label: "Regular",
        value: "REGULAR",
      },
      {
        label: "Hefty",
        value: "HEFLY",
      },
    ],
    stomachStructure: [
      {
        label: "Flat",
        value: "FLAT",
      },
      {
        label: "Average",
        value: "AVERAGE",
      },
      {
        label: "Rounded",
        value: "ROUNDED",
      },
    ],
    physicalSizes: {},
    layer: {
      show: false,
      videoUrl: "",
    },
    isOpenModalUpload: false,
  }),
  components: {
    Radio,
    Input,
    Select,
    Layer,
    UploadPhotosModal,
  },
  methods: {
    goBack() {
      const query = (() => {
        return {
          suitId: this.suitId,
          ...(this.userId ? { userId: this.userId } : {}),
        };
      })();
      this.$router.push({
        path: "view-suit",
        query: query,
      });
    },
    async onContinue(e) {
      e.preventDefault();
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      try {
        this.loading = true;
        const files = [];
        for (const key of Object.keys(this.formData.files)) {
          if (this.formData.files[key]) {
            files.push(this.formData.files[key]);
          }
        }
        if (files.length > 0) {
          const result = await uploadMultiImage(files);
          if (result && result.length > 0) {
            if (this.formData.files.avatar) {
              this.formData.avatar = result[0].url;
              if (this.formData.files.user_image) {
                this.formData.user_image = result[1].url;
              }
            } else {
              this.formData.user_image = result[0].url;
            }
          }
          delete this.formData.files
        }
        if (!this.suitId) {
          this.$router.push({
            path: "suit",
          });
          this.loading = false
        } else {
          let result;
          if (this.userId) {
            result = await api.User.updateGuest(this.userId, this.formData);
          } else {
            result = await api.User.createGuestUser(this.formData);
          }
          this.$router.push({
            path: "payment",
            query: { suitId: this.suitId, userId: result.id },
          });
        }

      } catch (error) {
        alert(error.message);
      } finally {
        this.loading = false;
      }
    },
    showIntroVideo(intro) {
      this.layer = {
        show: true,
        videoUrl: intro,
      };
    },
    convertToFullData(formData) { },
  },
  created: async function () {
    window.scrollTo(0, 0);
    this.suitId = this.$route.query.suitId;
    if (!this.suitId) {
      this.$router.push({ path: "suit" });
      return;
    }
    try {
      this.loading = true;
      this.userId = this.$route.query.userId;
      const resp = await Promise.all([
        api.Suit.getSuit(this.suitId),
        api.User.getPhysicalSizes(),
      ]);
      if (resp) {
        this.suitData = resp[0];
        this.physicalSizes = resp[1];
      }
      if (this.userId) {
        const data = await api.User.viewGuest(this.userId);
        for (const property in data) {
          if (data[property]) {
            this.formData[property] = data[property];
          }
        }
      }
    } catch (error) {
      alert(error.message);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.action {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .continue {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;

    button {
      width: 218px;
      height: 39px;
      background: #152940;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      color: #fff;
      text-transform: uppercase;
    }
  }

  .upload-file {
    width: 210px;
    align-self: flex-start;

    .upload-title {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 11px;
      color: #304251;
    }

    .upload-sub-title {
      color: rgba(48, 66, 81, 0.5);
      font-family: "Poppins";
      font-weight: 600;
      font-size: 9px;
      line-height: 13px;
    }

    button {
      width: 135px;
      height: 38px;
      background: #8e9c9c;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      font-family: "Source Sans Pro";
      font-size: 14px;
      color: white;
      margin-top: 12px;
      margin-bottom: 4px;
      min-width: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .file-err-visibility {
      visibility: hidden;
    }

    .file-err-invisibility {
      visibility: visible;
    }
  }

  .shoe-size {
    padding-top: 10px;
  }
}


.custom-gutters {
  margin-left: -10px;
  margin-right: -10px;

  .custom-col-gutters {
    padding: 0 10px;
  }

  &.row-5 {
    .custom-col-gutters {
      max-width: 25%;
    }
  }
}

.container-form {
  width: 750px;
  margin: 25px auto;
  padding: 26px 46px 50px 44px;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  .img-file {
    text-align: center;

    img {
      width: 75px;
    }
  }

  .hidden-field {
    display: none;
  }

  .suit-title {
    text-align: center;
    margin-top: 24px;
    color: #304251;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 15px;
  }

  .help-text {
    font-weight: 700;
    text-align: center;
    margin-bottom: 45px;
  }

  .go-back {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px;
    color: #304251;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 1px solid white;

    .v-icon {
      align-self: center;
    }
  }
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
  .action {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 20px;

    .shoe-size {
      padding: 0;
    }
  }

  .container-form {
    width: 100%;
    border-radius: 0;
    margin: 0 auto;

    .custom-gutters {
      display: grid;
      grid-template-columns: 1fr 1fr;

      &.row-5 {
        .custom-col-gutters {
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) and (min-width: 480px) {
  .action {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 20px;

    .shoe-size {
      padding: 0;
    }
  }

  .container-form {
    width: 100%;
    border-radius: 0;
    margin: 0 auto;

    .custom-gutters {
      display: grid;
      grid-template-rows: 1fr 1fr;

      &.row-5 {
        .custom-col-gutters {
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .container-form {
    padding: 26px 25px 50px 25px;
    width: 100%;
    border-radius: 0;
    margin: 0 auto;

    .custom-gutters {
      display: grid;
      grid-template-rows: 1fr 1fr;

      &.row-5 {
        .custom-col-gutters {
          max-width: 100%;
        }
      }
    }
  }

  .action {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, minmax(min-content, max-content));
    justify-content: center;

    .upload-file,
    .shoe-size,
    .continue {
      justify-self: center;
      width: 210px;
      margin-top: 10px;

      button {
        width: 100%;
      }
    }

    .upload-file {
      display: grid;

      button {
        justify-self: center;
        width: 100%;
      }
    }
  }
}

.v-divider {
  margin-top: 4px;
}

.nth-item {
  margin-top: 30.5px;
}

.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #00000038;
}
</style>
