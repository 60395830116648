<template>
  <v-dialog
    content-class="ui-modal"
    v-model="show"
    persistent
    eager
    internal-activator
    ref="dialogRef"
    @click:outside="handleClickClose()"
    :scrollable="true"
    @keydown.esc="handleClickClose()"
  >
    <v-card>
      <section class="header">
        <span>Upload Photos</span>
        <button class="close-btn" @click="handleClickClose()">
          <v-icon> mdi-close</v-icon>
        </button>
      </section>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="upload-photo">
            <h4 class="upload-title">
              Upload a full length forward facing photo of yourself
              <span>*</span>
            </h4>
            <img
              class="image"
              :src="previewFile.facingImage || facingImageUrl || defaultImage"
            />
            <button type="button" @click="onChooseFile('facingImgId')">
              {{
                facingImage && facingImage.name
                  ? facingImage.name
                  : facingImageUrl
                  ? facingImageUrl
                  : "Choose File"
              }}
            </button>
            <v-file-input
              ref="facingImgRef"
              id="facingImgId"
              type="file"
              v-model="facingImage"
              name="facing-img"
              :rules="facingImageUrl ? [] : rules.required"
              accept="image/*"
              @change="onSelectFile($event, 'facingImage')"
            />
            <h5 class="upload-sub-title">
              Do us a favor and please be clothed
            </h5>
            <v-slide-y-transition
              v-if="
                $refs.facingImgRef &&
                $refs.facingImgRef.validations &&
                $refs.facingImgRef.validations[0]
              "
            >
              <div
                v-show="$refs.facingImgRef.validations[0]"
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      This field is required.
                    </div>
                  </div>
                </div>
              </div>
            </v-slide-y-transition>
          </div>
          <v-divider />
          <div class="upload-photo">
            <h4 class="upload-title">
              Upload a full length side/profile photo of yourself <span>*</span>
            </h4>
            <img
              class="image"
              :src="
                previewFile.userSuitImage || userSuitImageUrl || defaultImage
              "
            />
            <button type="button" @click="onChooseFile('userSuitImgId')">
              {{
                userSuitImage && userSuitImage.name
                  ? userSuitImage.name
                  : userSuitImageUrl
                  ? userSuitImageUrl
                  : "Choose File"
              }}
            </button>
            <h5 class="upload-sub-title">
              Do us a favor and please be clothed
            </h5>
            <v-file-input
              ref="userSuitImgRef"
              id="userSuitImgId"
              type="file"
              :rules="userSuitImageUrl ? [] : rules.required"
              v-model="userSuitImage"
              name="user-suit-img"
              accept="image/*"
              @change="onSelectFile($event, 'userSuitImage')"
            />
            <v-slide-y-transition
              v-if="
                $refs.userSuitImgRef &&
                $refs.userSuitImgRef.validations &&
                $refs.userSuitImgRef.validations[0]
              "
            >
              <div
                v-show="$refs.userSuitImgRef.validations[0]"
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      This field is required.
                    </div>
                  </div>
                </div>
              </div>
            </v-slide-y-transition>
          </div>
          <v-divider />
          <div class="describe-build">
            <h4 class="describe-build-title">
              Describe your Build Type: <span>*</span>
            </h4>
            <v-textarea
              :rules="rules.required"
              solo
              name="input-7-4"
              label="Type here..."
              v-model="description"
              ref="descriptBuildRef"
              hide-details="true"
            ></v-textarea>
            <h5 class="describe-build-sub-title">
              Ex: long arm span, broad shoulders, tall/lean, average build,
              muscular/big thighs, long/short torso, long/short legs, carries
              weight in midsection, athletic build, etc.
            </h5>
            <v-slide-y-transition
              v-if="
                $refs.descriptBuildRef &&
                $refs.descriptBuildRef.validations &&
                $refs.descriptBuildRef.validations[0]
              "
            >
              <div
                v-show="$refs.descriptBuildRef.validations[0]"
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      This field is required.
                    </div>
                  </div>
                </div>
              </div>
            </v-slide-y-transition>
          </div>
          <button type="button" @click="handClickDone($event)">Done</button>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DefaultImage from "../../assets/img/default-image.jpeg";
export default {
  props: {
    show: Boolean,
    facingImageUrl: String,
    userSuitImageUrl: String,
    desciptioInput: String,
  },
  data() {
    return {
      valid: false,
      facingImage: undefined,
      userSuitImage: undefined,
      defaultImage: DefaultImage,
      description: "",
      rules: {
        required: [(v) => !!v || "This field is required."],
      },
      previewFile: {
        facingImage: "",
        userSuitImage: "",
      },
    };
  },
  watch: {
    "$props.desciptioInput": {
      handler(data) {
        this.description = data;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSelectFile(file, key) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (_file) => {
          this.previewFile[key] = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    handClickDone() {
      const validationResult = this.$refs.form.validate();
      if (validationResult) {
        this.$emit("onOk", {
          facingImage: this.facingImage,
          userSuitImage: this.userSuitImage,
          description: this.description,
        });
      }
    },
    handleClickClose() {
      this.$emit("onClose");
    },
    onChooseFile(id) {
      const el = document.getElementById(id);
      el.click();
    },
  },
};
</script>

<style lang="scss">
.ui-modal {
  width: 514px;
  .close-btn {
    background: none;
    border: none;
    box-shadow: none;
    color: #808080;
    position: absolute;
    top: 21px;
    right: 30px;
    width: fit-content;
    height: fit-content;
  }

  .v-card {
    text-align: center;
    padding-top: 57px;
    padding-bottom: 46px;
    .v-card_text {
      padding: 0px 22px 25px 22px;
    }
  }
  .v-dialog > .v-card > .v-card__text {
  }
  .v-sheet.v-card {
    border-radius: 15px;
  }
  .header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0.5em;
  }
  .video-preview {
    width: 100%;
  }
  .upload-title,
  .describe-build-title {
    span {
      color: #ff0000;
    }
  }
  .upload-photo {
    text-align: start;
    .upload-title {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 11px;
      color: #304251;
      margin-bottom: 1rem;
    }
    .image {
      width: 134px;
      height: auto;
      margin-bottom: 10px;
      display: block;
    }
    .upload-sub-title {
      color: rgba(48, 66, 81, 0.5);
      font-family: "Poppins";
      font-weight: 600;
      font-size: 9px;
      line-height: 13px;
      margin-top: 0.5rem;
    }
    button {
      width: 135px;
      height: 38px;
      background: #8e9c9c;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      font-family: "Source Sans Pro";
      font-size: 14px;
      color: white;
      min-width: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .file-err-visibility {
      visibility: hidden;
    }
    .file-err-invisibility {
      visibility: visible;
    }
    .v-input {
      display: none;
    }
  }
  .v-divider {
    margin: 24px 0;
  }
  .describe-build {
    text-align: start;
    .describe-build-title {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 11px;
      color: #304251;
    }
    .v-input__slot {
      box-shadow: none !important;
      border: 1px solid #d9d9d9;
      font-size: 14px;
      margin: 0;
      .v-label {
        top: 15px !important;
        font-size: 14px;
      }
    }
    .describe-build-sub-title {
      color: rgba(48, 66, 81, 0.5);
      font-family: "Poppins";
      font-weight: 600;
      font-size: 9px;
      line-height: 13px;
      margin-top: 0.5rem;
    }
  }
  button {
    width: 135px;
    height: 38px;
    background: #152940;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: #fff;
    margin-top: 1rem;
  }
}
</style>
